@tailwind base;
@tailwind components;
@tailwind utilities;

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

img,
video {
    max-width: 100%;
    height: auto;
}

body {
    font-family: 'Pretendard', sans-serif;
    word-break: keep-all;
}

.ourServiceSwiper .swiper-button-prev,
.ourServiceSwiper .swiper-button-next {
    color: #fff;
    top: auto;
    bottom: 0;
}

.ourServiceSwiper .swiper-button-prev {
    left: auto;
    right: 60px;
}

.ourServiceSwiper .swiper-button-prev::after,
.ourServiceSwiper .swiper-button-next::after {
    font-size: 12px;
    font-weight: bold;
}

.activeBtn {
    background-color: #fff;
    color: #111;
    font-weight: bold;
}

.activeList {
    font-weight: bold;
    color: #fff;
    position: relative;
}

.activeList::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #fff;
    left: 0;
    bottom: -2px;
}

.activeList:last-child::after {
    width: calc(100% - 20px);
}

.scrollbar-hidden {
    overflow-x: auto;
}

.scrollbar-hidden::-webkit-scrollbar, .scrollbar-hidden-y::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.scrollbar-hidden, .scrollbar-hidden-y {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollbar-hidden-y {
    overflow-y: auto;
}

.transition-master {
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
}

.transition-opacity {
    transition: opacity 0.5s ease-in-out;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}